import { Block, BlockEnum } from "common/types/graphql"
import { BlocksRenderer } from "../BlocksRenderer"
import clsx from "classnames"

export interface ParagraphProps {
  blocks?: Block[]
  text?: string
}

export const Paragraph = ({ blocks, text }: ParagraphProps) => {
  const trimmedText = text?.trim()
  const hasBlocks =
    blocks &&
    blocks.length > 0 &&
    blocks.some(({ type, props }) => type !== BlockEnum.Text || props.text?.trim()?.length > 0)

  const hasText = trimmedText && trimmedText.length > 0
  return (
    <p
      className={clsx("max-w-screen-lg font-serif lg:text-xl", {
        "mb-4": hasBlocks || hasText,
      })}
    >
      {blocks && <BlocksRenderer blocks={blocks} />}
      {trimmedText}
    </p>
  )
}
